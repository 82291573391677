@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./modules/auth/auth.css";

@import "./modules/core/components/navbar/Navbar.css";
@import "./modules/core/components/sidenavbar/SideNavbar.css";

@layer base {
  html,
  body {
    @apply overflow-hidden bg-primary text-primary-text dark:bg-dark-primary dark:text-dark-text;
  }

  span,
  p {
    @apply text-dark-text dark:text-dark-text;
  }
}
