@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .auth-form {
    @apply mx-auto my-8 flex w-5/6 max-w-[1248px] flex-col items-center justify-center gap-4 rounded-lg bg-primary p-4 shadow-2xl sm:w-[600px] xl:grid xl:w-3/4 xl:grid-cols-2;

    h1 {
      @apply mb-8 text-center text-2xl font-bold text-dark-secondary dark:text-dark-text;
    }

    > div {
      @apply w-full px-2 py-10 sm:p-10 xl:px-0;
    }

    button {
      @apply mt-4 w-full rounded-md bg-dark-accent p-2 text-primary-text shadow-md transition-all duration-200 ease-in-out hover:bg-accent dark:bg-accent dark:hover:bg-dark-accent;
    }

    button:disabled {
      @apply cursor-not-allowed opacity-75;
    }

    .checkbox-container {
      input {
        @apply hidden;
      }

      svg {
        @apply overflow-visible shadow-md;
      }

      .path {
        fill: none;
        stroke: #ddd;
        stroke-width: 4;
        stroke-linecap: round;
        stroke-linejoin: round;
        transition:
          stroke-dasharray 0.5s ease,
          stroke-dashoffset 0.5s ease,
          stroke 0.4s ease-out;
        stroke-dasharray: 241 9999999;
        stroke-dashoffset: 0;
      }

      input:checked ~ svg .path {
        stroke: #3467ff;
        stroke-dasharray: 70.5096664428711 9999999;
        stroke-dashoffset: -262.2723388671875;
      }
    }

    .input-container {
      @apply relative mt-2 w-full;
    }

    .input-container input {
      @apply w-full rounded-md bg-primary p-2 text-dark-text shadow-md outline-0 transition-all duration-300 ease-in-out focus:ring-2 focus:ring-accent dark:text-dark-text;
    }

    .input-container label {
      @apply pointer-events-none left-0 top-0 ml-2 bg-primary px-0.5 text-dark-secondary transition-all duration-200 ease-in-out dark:text-dark-text;
      transform: translateY(0.5rem);
      position: absolute;
    }

    .input-container input:focus ~ label,
    .input-container input.filled ~ label {
      transform: translateY(-55%) scale(0.8);
    }

    .input-container input.error {
      @apply ring-1 ring-red-600 focus:ring-2;
    }

    .input-container input:focus ~ label {
      @apply text-accent;
    }

    .input-container input.error ~ label {
      @apply text-red-600;
    }

    a {
      @apply text-dark-accent hover:text-accent dark:text-accent dark:hover:text-dark-accent;
    }
  }
}
